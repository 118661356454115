@keyframes shake {
  0% {
    transform: translateX(0);
  }
  12.5% {
    transform: translateX(-6px) rotateY(-5deg);
  }
  37.5% {
    transform: translateX(5px) rotateY(4deg);
  }
  62.5% {
    transform: translateX(-3px) rotateY(-2deg);
  }
  87.5% {
    transform: translateX(2px) rotateY(1deg);
  }
  100% {
    transform: translateX(0);
  }
}
.shake {
  animation: shake 400ms ease-in-out;
}
md-toast.md-error-toast-theme {
  background-color: #FFC9C9;
}
.user-avatar,
.md-avatar {
  border-radius: 99%;
  width: 60px !important;
  height: 60px !important;
}
md-menu-item.active {
  background-color: #DDD;
}
.avatarSelect {
  overflow-x: auto;
}
md-sidenav {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}
ng-form.formly md-input-container {
  width: 100%;
}
md-dialog {
  max-height: 90% !important;
}
md-dialog md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
  min-height: 400px !important;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/resources/fonts/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(/resources/fonts/MaterialIcons-Regular.woff2) format('woff2'), url(/resources/fonts/MaterialIcons-Regular.woff) format('woff'), url(/resources/fonts/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
tag-item {
  position: relative;
}
tag-item.new div {
  background: #FFFC00;
}
tag-item .close-fab {
  display: none;
  position: absolute;
  top: 3px;
  right: 1px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  box-shadow: 0px 0.5px 1px #777;
  cursor: pointer;
  background: white;
}
tag-item:hover .close-fab {
  display: inherit;
}
.corrigeTexte {
  margin: 10px 0;
  color: red;
}
.corrigeTexte .valide {
  color: green;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
.breadcrumb {
  padding: 5px 10px;
  font-size: 0.8em;
}
.breadcrumb .divider {
  margin: 0 5px;
}
.defilement-texte.defilement {
  position: relative;
  min-height: 150px;
}
.defilement-texte.defilement #wrapper {
  position: absolute;
  top: 0;
}
.defilement-texte .fade {
  transition: all linear 250ms;
}
.defilement-texte .fade.hidden {
  color: transparent;
}
